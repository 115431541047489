$spacer: 1rem;

$spacers: (0: 0,
  1: $base-unit * 0.25, // 4px
  2: $base-unit * 0.5, // 8px
  3: $base-unit, // 16px
  4: $base-unit * 1.5, // 24px
  5: $base-unit * 2, // 32px
  6: $base-unit * 3, // 48px
  7: $base-unit * 4, // 64px
  8: $base-unit * 6, // 96px
  9: $base-unit * 8, // 128px
  40px: $base-unit * 2.5, // 40px
);
