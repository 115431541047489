$line-height-base: 1.3; // default Bootstrap
$headings-line-height: 1.3; // default Bootstrap
$font-family-base: 'Anek Latin',
sans-serif; // default Bootstrap

$font-weight-normal: 400; // default Bootstrap
$font-weight-medium: 500; // default Bootstrap
$font-weight-bold: 700; // default Bootstrap

$h1-font-size: $base-unit * 2 !default; // 32px
$h2-font-size: $base-unit * 1.5 !default; // 24px
$h3-font-size: $base-unit * 1.25 !default; // 20px
$h4-font-size: $base-unit !default; // 16px
$h5-font-size: $base-unit !default; // 16px
$h6-font-size: $base-unit !default; // 16px

/*
  Utility to change fonts according breakpoints
  e.g. <div className="f-3 f-md-5 f-lg-8"></div>
*/

$fonts: (1: '10px',
  2: '12px',
  3: '14px',
  4: '16px',
  5: '18px',
  6: '20px',
  7: '22px',
  8: '35px',
  9: '45px',
  10: '56px',
);

@each $size,
$value in $fonts {
  .f-#{$size} {
    font-size: #{$value} !important;
  }
}

@each $infix,
$breakpoint in $grid-breakpoints {

  @each $size,
  $value in $fonts {
    .f-#{$infix}-#{$size} {
      @media (min-width: $breakpoint) {
        font-size: #{$value} !important;
      }
    }
  }
}

html {
  font-family: $font-family-base !important;
}

.font-roboto {
  font-family: $font-family-base !important;
}

.font-poppins {
  font-family: Poppins !important;
}

@media (max-width: 992px) {

  h1,
  .h1 {
    font-size: $base-unit * 1.5 !important;
  }

  h2,
  .h2 {
    font-size: $base-unit * 1.25 !important;
  }

  h3,
  .h3 {
    font-size: $base-unit * 1.125 !important;
  }

  h4,
  .h4 {
    font-size: $base-unit !important;
  }

  h5,
  .h5 {
    font-size: $base-unit !important;
  }

  h6,
  .h6 {
    font-size: $base-unit !important;
  }
}

.font-weight-medium {
  font-weight: $font-weight-medium !important;
}
