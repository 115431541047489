$theme-colors: (
  'primary-lightest': #FFA8AD, // TODO-DS: Validar
  'primary-light': #d44f70, // TODO-DS: Validar FF5B67
  'primary-medium': #ce355b, // TODO-DS: Validar E2173D
  'primary': #C20332,
  'primary-dark': #5C0521, // TODO-DS: Remover
  'primary-darkest': #710000, // TODO-DS: Remover
  'secondary-lightest': #B0EEFF, // TODO-DS: Remover
  'secondary-light': #8d5064,
  'secondary-medium': #7d374d,
  'secondary': #5C0521,
  'secondary-dark': #0058A0, // TODO-DS: Remover
  'secondary-darkest': #005084, // TODO-DS: Remover
  'neutral-lightest': #fff,
  'neutral-lighter': #FAFAFB,
  'neutral-light': #F2F2F2, // TODO-DS: Validar
  'neutral-medium-lightest': #f2f2f2, // TODO-DS: Validar
  'neutral-medium-light': #EAEEF3,
  'neutral-medium': #C5CED7,
  'neutral': #8A99A8,
  'neutral-darker': #5A646E,
  'neutral-dark': #353A40,
  'neutral-darkest': #1B1D20,
  'success-lightest': #E9FBD3, // TODO-DS: Validar
  'success-light': #A9EA7B, // TODO-DS: Validar
  'success-medium': #23a148, // TODO-DS: Validar
  'success': #5BBC49,
  'warning-lightest': #FEF9CB, // TODO-DS: Validar
  'warning-light': #FAE865, // TODO-DS: Validar
  'warning-medium': #ffe200, // TODO-DS: Validar
  'warning': #F99500,
  'alert-lightest': #FEE9D3, // TODO-DS: Validar
  'alert-light': #F99500,
  'alert-medium': #F25024, // TODO-DS: Validar
  'alert':#FF0000,
  'dark':#000000,
);

$metro-stations-colors: (
  'green': #008061,
  'blue': #0153a0,
  'red': #ee3e34,
  'yellow': #fed304,
  'lilac': #784d9f,
  'silver': #9e9e93,
);

$white: map-get($theme-colors, 'neutral-lightest');
$gray-100: map-get($theme-colors, 'neutral-lightest');
$gray-200: map-get($theme-colors, 'neutral-lighter');
$gray-300: map-get($theme-colors, 'neutral-light');
$gray-400: map-get($theme-colors, 'neutral-medium-light');
$gray-500: map-get($theme-colors, 'neutral-medium-light');
$gray-600: map-get($theme-colors, 'neutral-medium');
$gray-700: map-get($theme-colors, 'neutral-medium');
$gray-800: map-get($theme-colors, 'neutral-dark');
$gray-900: map-get($theme-colors, 'neutral-darkest');
$black: map-get($theme-colors, 'neutral-darkest');

$grays: (
  '100': map-get($theme-colors, 'neutral-lightest'),
  '200': map-get($theme-colors, 'neutral-lighter'),
  '300': map-get($theme-colors, 'neutral-light'),
  '400': map-get($theme-colors, 'neutral-medium-light'),
  '500': map-get($theme-colors, 'neutral-medium'),
  '600': map-get($theme-colors, 'neutral-dark'),
  '700': map-get($theme-colors, 'neutral-darkest'),
  '800': $gray-800,
  '900': $gray-900,
);

$light: $gray-300;
$yiq-text-dark: $gray-900;

/*
  Utility to change background-color according breakpoints
  e.g. <div className="bg-md-primary bg-lg-secondary" />
 */
@each $infix, $breakpoint in $grid-breakpoints {
  @each $color, $value in $theme-colors {
    .bg-#{$infix}-#{$color} {
      @media (min-width: $breakpoint) {
        background-color: $value !important;
      }
    }
  }
}
