$border-width: 2px;

$border-radius: 1rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 1.5rem;

$border-r: (
  small: $base-unit * 0.5 /* 8px */,
  medium: $base-unit /* 16px */,
  large: $base-unit * 1.5 /* 24px */,
  pill: $base-unit * 7 /* 112px */,
  circle: 999rem,
);

.border-thin[class*='border'] {
  border-width: 1px !important;
}

.border-thick[class*='border'] {
  border-width: map-get($spacers, 2) !important;
}

.rounded-sm-bottom {
  border-radius: 0 0 8px 8px;
}
