.mh-button {
    min-height: 48px;
}

.u-text-decoration-underline {
    text-decoration: underline !important;
}

.mw-content {
    max-width: max-content !important;
}

.mw-lg-content {
    @include breakpoint(lg) {
        max-width: max-content !important;
    }
}

.u-w-content {
    width: max-content !important;
}

.u-w-lg-content {
    @include breakpoint(lg) {
        width: max-content !important;
    }
}

.u-text-darkest {
    color: map-get($theme-colors, neutral-darkest) !important;
}

.u-cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.pe-none {
    pointer-events: none !important;
}

.pe-auto {
    pointer-events: auto !important;
}

.u-line-height-0 {
    line-height: 0 !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.bg-fluid {
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
}

$overflow-values: (hidden, initial, scroll);

@each $infix,
$breakpoint in $grid-breakpoints {
    @each $overflow-value in $overflow-values {
        .overflow-#{$infix}-#{$overflow-value} {
            @media (min-width: $breakpoint) {
                overflow: #{$overflow-value} !important;
            }
        }
    }
}
