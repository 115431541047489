/*
 Positioning utility
 e.g. <div className="position-absolute end-0 top-50" />
 */
$pos: (
  top: top,
  left: start,
  bottom: bottom,
  right: end,
);

$position-values: (
  0: 0%,
  50: 50%,
  100: 100%,
);

/*
  transform/translate utility
  e.g. <div className="position-absolute transform-middle" />
*/
$translate-values: (
  x: translateX(-50%),
  y: translateY(-50%),
);

.translate-middle {
  transform: translate(-50%, -50%);
}

@each $position, $pos-name in $pos {
  @each $key, $value in $position-values {
    .#{$pos-name}-#{$key} {
      #{$position}: $value !important;
    }
  }
}

@each $translate-position, $translate-value in $translate-values {
  .translate-middle-#{$translate-position} {
    transform: #{$translate-value};
  }
}
